import React from "react"
import {injectIntl} from "gatsby-plugin-intl"
import {graphql, useStaticQuery} from "gatsby"
import styled from "styled-components"
import VideoButton from "../commonV3/VideoButton";
import gifWatch from "../../images/product/RelojDurcal_360.gif"
import BackgroundImage from 'gatsby-background-image'


const HowItWorks = ({intl,showVideo}) => {
    const images = useStaticQuery(graphql`
    query {
      img1: file(
        sourceInstanceName: { eq: "product" }
            name: { eq: "Banner-360-D-Reloj-Durcal-Teleasistencia-funcionalidades" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            originalName
            src
            srcSet
            srcSetWebp
            aspectRatio
            sizes
          }
        }
      }
    }
  `)

    return (
        <HowItWorksSectionWrapper>
            <TittleWrapper>
                <h5>  {intl.formatMessage({id: "homeV3FeaturesSubtitle"})}</h5>
            </TittleWrapper>
            <BackgroundImage
                Tag="section"
                fluid={images.img1.childImageSharp.fluid}>
            <Wrapper>
                <div className="DescriptionWrapper">
                    <h5>{intl.formatMessage({id: "howItWorksFeatures"})}</h5>
                    <div className="Description">
                        {intl.formatMessage({id: "howItWorksFeaturesDescription"})}
                    </div>
                    <VideoButton showVideo={showVideo} video={"how"} eventType={"product"} buttonText={intl.formatMessage({id: "discoverMore"})}/>
                </div>
              <GifWrapper>
                <img alt="Reloj Durcal" src={gifWatch} />
              </GifWrapper>
            </Wrapper>
            </BackgroundImage>
          <WrapperM>
            <div className="DescriptionWrapperM">
              <h5>{intl.formatMessage({ id: "howItWorksFeatures" })}</h5>
              <div className="Description">
                        {intl.formatMessage({id: "howItWorksFeaturesDescription"})}
                    </div>
                    <VideoButton showVideo={showVideo}  video={"how"} eventType={"product"} buttonText={intl.formatMessage({id: "discoverMore"})}/>
                </div>
            </WrapperM>
        </HowItWorksSectionWrapper>
    )
}


const HowItWorksSectionWrapper = styled.div`
  margin: 16px 16px 0 16px;
  align-items: center;
  //width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
  border-radius: 20px;
  overflow: auto;
  //height: 305px;
  justify-content: center;
  
  section {
    overflow: auto;
    border-radius: 20px 20px 0 0;
    @media (min-width: ${props => props.theme.screen.xl}) {
      border-radius: 20px;
    }    
  }
  @media (min-width: ${props => props.theme.screen.xl}) {
    margin: 64px 64px 0 64px;
  }
  
  @media (min-width: ${props => props.theme.screen.xl}) {
    flex-direction: row-reverse;
    gap:56px;
  }
  .backgroundWrapper {
    position: absolute;
    z-index: -100;
    width: 100%;
  }
  .DescriptionWrapper {
    width: 600px;
    height: 450px;
    margin-top: 64px;
    margin-left: 64px;
    padding: 24px 16px 16px;
    display: none;
    justify-content: center;
    flex-direction: column;
    border-radius: 20px;
    background-image: linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.25));
    @media (min-width: ${props => props.theme.screen.xl}) {
      display: flex;
      gap:24px;
    }
    h5 {
      margin: 0;
      ${({ theme }) => theme.font_size.large};
      font-family: ${({ theme }) => theme.font.bold};
      @media (min-width: ${props => props.theme.screen.xl}) {
        ${({ theme }) => theme.font_size.xlarge};
      }
    }
    
    .Description {
      ${({ theme }) => theme.font_size.small};
      font-family: ${({ theme }) => theme.font.thin};
      @media (min-width: ${props => props.theme.screen.xl}) {
        ${({ theme }) => theme.font_size.regular};
      }
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  //gap: 300px;
`

const WrapperM = styled.div`
  display: flex;
  flex-direction: row;
  //gap: 300px;
  .DescriptionWrapperM {
    margin-top: 0;
    padding: 24px 16px 16px;
    display: flex;
    gap:24px;
    justify-content: center;
    flex-direction: column;
    border-radius: 0 0 20px;
    background: #FFF;
    h5 {
      margin: 0;
      ${({ theme }) => theme.font_size.large};
      font-family: ${({ theme }) => theme.font.bold};
      @media (min-width: ${props => props.theme.screen.xl}) {
        ${({ theme }) => theme.font_size.xlarge};
      }
    }

    .Description {
      ${({ theme }) => theme.font_size.small};
      font-family: ${({ theme }) => theme.font.thin};
      @media (min-width: ${props => props.theme.screen.xl}) {
        ${({ theme }) => theme.font_size.regular};
      }
    }
    @media (min-width: ${props => props.theme.screen.xl}) {
      display: none;
    }
  }
  @media (min-width: ${props => props.theme.screen.xl}) {
    display: none;
  }
`

const GifWrapper = styled.div`
  @media (min-width: ${props => props.theme.screen.xl}) {
    margin-left: 250px;
  }
`
const TittleWrapper = styled.div`
  display: none;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  
  text-align: center;
  @media (min-width: ${props => props.theme.screen.xl}) {
    display: flex;
  }
  
  span {
    ${({theme}) => theme.font_size.small};
    font-family: ${({theme}) => theme.font.thin};
  }
  
  h5 {
    ${({theme}) => theme.font_size.xlarge};
    font-family: ${({theme}) => theme.font.bold};
    padding-bottom: 32px;
    margin: 0;
  }
`


export default injectIntl(HowItWorks)
