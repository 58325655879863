import React from "react"
import {injectIntl} from "gatsby-plugin-intl"
import {graphql, useStaticQuery} from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"


const AllSubscription = ({intl}) => {
    const images = useStaticQuery(graphql`
    query {
      img1: file(
        sourceInstanceName: { eq: "product" }
        name: { eq: "reloj-durcal-gps-detector-caidas-teleasistencia" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1500, quality: 100) {
            originalName
            src
            srcSet
            srcSetWebp
            aspectRatio
            sizes
          }
        }
      }
    }
  `)

    return (
        <AllSubscriptionSectionWrapper>
            <ImageWrapper>
                <StyledImage fluid={images.img1.childImageSharp.fluid} />
            </ImageWrapper>
            <div className="DescriptionWrapper">
                <h5>{intl.formatMessage({id: "allIncludedTitle"})}</h5>
                <div className="Description">{intl.formatMessage({id: "allIncludedDescription"})}</div>
            </div>
        </AllSubscriptionSectionWrapper>
    )
}


const AllSubscriptionSectionWrapper = styled.div`
  margin: 16px;
  
  /* Auto layout */
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  
  align-items: center;
  
  
  gap: 64px;

  @media (min-width: ${props => props.theme.screen.xl}) {
    margin: 64px;
    padding: unset;
  }
  
  /* Primary (Purple)/10 */
  background: ${({ theme }) => theme.color.purples.purple10};
  
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 20px;

  /* Inside auto layout */
  flex: none;
  order: 2;
  flex-grow: 0;

  @media (min-width: ${props => props.theme.screen.xl}) {
    flex-direction: row;
    gap:56px;
  }
  
  .DescriptionWrapper {
    width: 100%;
    gap: 8px;
    padding: 24px 16px 0;

    @media (min-width: ${props => props.theme.screen.xl}) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px 24px 0;
      gap: 0;
    }
    
    h5 {
      margin: 0;
      padding-top: 24px;
      ${({ theme }) => theme.font_size.large};
      font-family: ${({ theme }) => theme.font.bold};
      @media (min-width: ${props => props.theme.screen.xl}) {
        ${({ theme }) => theme.font_size.xlarge}
      }
    }
    
    .Description {
      margin-top: 8px;
      ${({ theme }) => theme.font_size.small};
      font-family: ${({ theme }) => theme.font.thin};
      @media (min-width: ${props => props.theme.screen.xl}) {
        ${({ theme }) => theme.font_size.regular};
      }
    }
    
  }
`

const ImageWrapper = styled.div`
  margin-top: 0;
  width: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: row;
`

const StyledImage = styled(Img)`
  display: block;
  border-radius: unset;
  @media (min-width: ${props => props.theme.screen.xl}) {
    border-radius: 20px;
  }
  margin-right: 10px;
  flex-grow: 1;
  
`
const StyledImage2 = styled(Img)`
  display: block;
  border-radius: 20px;
  margin-left: 10px;
  flex-grow: 1;
`


export default injectIntl(AllSubscription)
