//import React, {useState} from "react"
import React from "react"
import {injectIntl} from "gatsby-plugin-intl"
import styled from "styled-components"
import {graphql, useStaticQuery} from "gatsby"
import Img from "gatsby-image"
import {StringParam, withQueryParams} from "use-query-params";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import IUnion from "../../images/svgs/union2.svg";
import AppStore from "../../images/svgs/AppStore.svg"
import GooglePlay from "../../images/svgs/GooglePlay.svg"
import Rating from "../../images/svgs/Rating.svg"


const Header = ({intl, location, query}) => {
    const images = useStaticQuery(graphql`
    query {
      img1: file(
        sourceInstanceName: { eq: "product" }
        name: { eq: "Web_App_Desktop" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            originalName
            src
            srcSet
            srcSetWebp
            aspectRatio
            sizes
            ...GatsbyImageSharpFluid
          }
        }
      },
        img2: file(
        sourceInstanceName: { eq: "product" }
        name: { eq: "Web_App_Movil" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            originalName
            src
            srcSet
            srcSetWebp
            aspectRatio
            sizes
            ...GatsbyImageSharpFluid
          }
        }
      }

    }
  `)
//                   {intl.formatMessage({id: "homeV3HeaderTitle"})}
    return (

        <AppSectionWrapper>
            <ImageWrapperDesktop>
                <StyledImage fluid={images.img1.childImageSharp.fluid} />
            </ImageWrapperDesktop>
            <DescriptionWrapper>
                <h1>Con la APP Durcal sabrás <br class="responsive"/>
                    si los tuyos están bien.</h1>
                <span>
                    La tranquilidad que necesitas.
                </span>
                <FeaturesDetailWrapper>
                    <Feature><IUnion/>Recibe avisos y alertas en tiempo real.</Feature>
                    <Feature><IUnion/>Consulta su ubicación en el mapa.</Feature>
                    <Feature><IUnion/>Estáte al tanto de su salud.</Feature>
                    <Rating className="ratingMobile" preserveAspectRatio="xMinYMin meet"/>
                </FeaturesDetailWrapper>
                <ImageWrapperMobile>
                    <StyledImage fluid={images.img2.childImageSharp.fluid} />
                </ImageWrapperMobile>
              <Rating className="ratingDesktop"/>
                <StoresWrapper>
                    <a href="https://play.google.com/store/apps/details?id=app.alpify&hl=es" target="_blank"><GooglePlay /></a>
                    <a href="https://apps.apple.com/es/app/durcal-localizador-familiar/id622546357" target="_blank"> <AppStore /></a>
                </StoresWrapper>
            </DescriptionWrapper>
        </AppSectionWrapper>
    )
}

const AppSectionWrapper = styled.div`
  background: #ECEBFD;
  border-radius: 20px;
  margin: 26px;
   
  @media (min-width: ${props => props.theme.screen.xl}) {
    flex-direction: row;
    justify-content: space-between;
    height: 840px;
    background: unset;
    backdrop-filter: unset;
    border-radius: unset;
    margin: unset;
  }
`

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position:relative;
  margin: 30px;
  br.responsive {display: none}
  
  h1 {
    ${({theme}) => theme.font_size.large};
    font-family: ${({theme}) => theme.font.bold};
  }
  
  span {
    ${({ theme }) => theme.font_size.regular};
    font-family: ${({ theme }) => theme.font.bold};
    color: ${({ theme }) => theme.color.purple};
  }
    
  .ratingDesktop {
      color:#FFF;
      margin-top: 32px;
      @media (max-width: ${props => props.theme.screen.xl}) {
          display: none;
      }
  } 
  .ratingMobile {
      color:#4528B3;
      margin-bottom: 10px;
      width: 100%;
      @media (min-width: ${props => props.theme.screen.xl}) {
          display: none;
      }
  }
   @media (min-width: ${props => props.theme.screen.xl}) {
    top: 100px;  
    width: 50%;  
    margin-bottom: 30px;
    align-items: baseline;  
    margin-left: 64px;  
    text-align: left;  
    color: #FFF;  
    gap: 8px;
     br.responsive {display: block}
     h1 {
       ${({ theme }) => theme.font_size.xlarger};
     }
     span {
       ${({ theme }) => theme.font_size.xlarge};
       margin-bottom: 44px;
       color: #FFF;
     }
   }
`
const StoresWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 30px;
  justify-content: center;
  svg {
    //transform: scale(1.5);
  }
  @media (min-width: ${props => props.theme.screen.xl}) {
    margin: 50px 0 0 0;
    //gap: 20px;
    justify-content: unset;
    svg {
      //transform: unset;
    }
  }
`

const ImageWrapperDesktop = styled.div`
  margin-top: 0;
  width: 100%;
  background-color: transparent;
  position: absolute;
  z-index: -100;
  display: none;
  @media (min-width: ${props => props.theme.screen.xl}) {
    display: block;
  }
`

const ImageWrapperMobile = styled.div`
  display: block;
  width: 100%;
  img {
    border-radius:20px;
  }
  @media (min-width: ${props => props.theme.screen.xl}) {
    display: none;
    margin: unset;
    border-radius: unset;
  } 
`

const StyledImage = styled(Img)`
  width: 100%;
  display: block;
`

const FeaturesDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 12px;
  padding-bottom: 16px;
  gap: 10px;
  width: 100%;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
  svg {
    color: ${({ theme }) => theme.color.purple};
    min-height: 18px;
    min-width: 18px;
  }
  @media (min-width: ${props => props.theme.screen.xl}) {
    gap: 32px;
    padding: 0;
    svg {
      color: #FFF;
    }
  }
  `
const Feature = styled.div`
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 8px;
  width: 100%;
  flex: none;
  align-self: stretch;
  flex-grow: 0;

  @media (min-width: ${props => props.theme.screen.xl}) {
    ${({ theme }) => theme.font_size.regularr};
    font-family: ${({ theme }) => theme.font.thin};  
  }
`


export default withQueryParams(
    {
        purchase_method: StringParam,
        userId: StringParam,
        promotion_code: StringParam,
    },
    injectIntl(Header),
)

